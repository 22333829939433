/**
 * Functions for operating on or manipulating DOM text.
 *
 * By convention, we import this module as DomText.
 */
import Dom = require("Everlaw/Dom");
import Str = require("Everlaw/Core/Str");
import Window = require("Everlaw/Window");
import { userHasFirefox } from "Everlaw/Core/Sniff";

/**
 * Escapes special HTML characters so that they are safe to use as innerHTML. They are NOT escaped
 * for use in entity attributes, but we shouldn't be using string concatenation in that way, anyway.
 */
export function escapeHtml(str: string) {
    // Don't return the string "null" or "undefined".
    if (!str) {
        return "";
    }
    // Use the browser's built-in functionality to quickly and safely escape the string.
    const div = document.createElement("div");
    div.appendChild(document.createTextNode(str));
    return div.innerHTML;
}

/**
 * Extracts text content from an HTML string.
 */
export function htmlToText(str: string) {
    // Strip out tags and any stray < characters, to make sure str is safe to set as innerHTML
    str = str.replace(/<[^>]*>|</g, "");
    // Decode character entities by parsing as HTML and reading back as text
    const div = document.createElement("div");
    div.innerHTML = str;
    str = div.textContent;
    // Collapse spaces (gets rid of hard newlines, etc.)
    return str.replace(/\s+/g, " ");
}

/**
 * Get the text content of a node with newline characters added in where the text is forced to
 * break by a BR or block element. This is useful to get the text from a contentEditable element
 * since browsers may create line breaks with elements rather than '\n' characters, even if it
 * has white-space set to pre.
 *
 * This function is not currently written to operate effectively on handwritten HTML. For starters,
 * it would maintain all of the source file's original whitespace. It's non-trivial to do the "right
 * thing" (whatever that would be) correctly, and it's not our current intended use-case, anyway.
 */
export function getTextWithNewlines(node: Node) {
    let text = "";
    visit(node);
    return text;
    function visit(parent: Node) {
        for (let n: Node = parent.firstChild; n; n = n.nextSibling) {
            if (n instanceof Text) {
                text += n.nodeValue;
            } else if (n instanceof HTMLBRElement) {
                text += "\n";
            } else if (n instanceof HTMLTextAreaElement) {
                text += n.value;
            } else if (n instanceof HTMLElement) {
                const isBlock = Dom.getStyle(n, "display") === "block";
                // The beginning and end of a block element break up lines, but do not create empty
                // lines like the <BR> element does.
                if (isBlock && !Str.endsWith(text, "\n")) {
                    text += "\n";
                }
                visit(n);
                if (isBlock && !Str.endsWith(text, "\n")) {
                    text += "\n";
                }
            }
        }
    }
}

/**
 * Places the cursor at the start or end of a contenteditable element.
 * @param {boolean} start
 */
export function placeCaret(el: HTMLElement, start: boolean) {
    el.focus();
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(start || false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}

export function placeCaretAtPos(el: HTMLElement, pos: number) {
    el.focus();
    if (el instanceof HTMLTextAreaElement) {
        el.selectionStart = pos;
        el.selectionEnd = pos;
    } else {
        const range = document.createRange();
        range.selectNodeContents(el);
        const textNode = range.startContainer.firstChild;
        range.setStart(textNode, pos);
        range.setEnd(textNode, pos);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }
}

export function getCaretPosition(el: Node) {
    const atStart = textBeforeCaret(el) === "";
    const atEnd = textAfterCaret(el) === "";
    return { begin: atStart, end: atEnd };
}

export function textBeforeCaret(el: Node) {
    if (el instanceof HTMLTextAreaElement) {
        return el.value.substring(0, el.selectionStart);
    } else {
        const curRange = window.getSelection().getRangeAt(0);
        const range = document.createRange();
        range.selectNodeContents(el);
        range.setEnd(curRange.startContainer, curRange.startOffset);
        return range.toString();
    }
}

export function textAfterCaret(el: Node) {
    if (el instanceof HTMLTextAreaElement) {
        return el.value.substring(el.selectionEnd);
    } else {
        const curRange = window.getSelection().getRangeAt(0);
        const range = document.createRange();
        range.selectNodeContents(el);
        range.setStart(curRange.endContainer, curRange.endOffset);
        return range.toString();
    }
}

export function hasSelectedText() {
    const focusedEl = document.activeElement;
    // Window.getSelection() has slightly different behavior in Firefox as described
    // here: https://bugzilla.mozilla.org/show_bug.cgi?id=85686
    if (userHasFirefox() && focusedEl instanceof HTMLTextAreaElement) {
        return !!focusedEl.value.substring(focusedEl.selectionStart, focusedEl.selectionEnd);
    }
    return !!window.getSelection().toString();
}

/**
 * Places text before the current cursor position on the screen.
 */
export function insertTextBeforeCursor(text: string) {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
        const range = sel.getRangeAt(0);
        range.deleteContents();
        const node = document.createTextNode(text);
        range.insertNode(node);
        range.setStartAfter(node);
        range.setEndAfter(node);
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    }
}

// Works on IE>=9, other modern browsers.
export function selectText(node: Node) {
    if (!getTextWithNewlines(node)) {
        // There is no text to select.
        return;
    }
    const range = document.createRange();
    range.selectNodeContents(node);
    const sel = window.getSelection();
    // This condition avoids "Could not complete the operation due to error 800a025e" in IE.
    if (sel.rangeCount > 0 && sel.getRangeAt(0).getClientRects().length > 0) {
        sel.removeAllRanges();
    }
    sel.addRange(range);
}

/**
 * Initiate a download of the given text in format specified by type, if type is provided.
 * Exports as a UTF-8 encoded file by default.
 */
export function downloadText(filename: string, text: string, type = "octet/stream") {
    const data = new Blob([text], { type });
    if ((window.navigator as any).msSaveOrOpenBlob) {
        // workaround for downloading in IE & Edge
        // TS 4.4 and later doesn't have MSFileSaver, so cast window.navigator to any
        // https://github.com/microsoft/TypeScript/issues/45612
        (window.navigator as any).msSaveBlob(data, filename);
        return;
    }

    const url = URL.createObjectURL(data);
    try {
        Window.autoClickHref({ href: url, download: filename });
    } finally {
        URL.revokeObjectURL(url);
    }
}

/**
 * Initiate a download of given csv content as a csv file.
 *
 * @param filename Target filename, should include extension.
 * @param content Csv content in [row][col] format.
 * @param header The first row in the downloaded csv file.
 *
 * @deprecated Use `CsvUtil#downloadCsv`, which comes with sanitation of the content
 */
export function downloadCsv(filename: string, content: string[][], header?: string[]) {
    let csv = header ? header.join(",") + "\n" : "";
    content.forEach((line: string[]) => (csv += line.join(",") + "\n"));
    downloadText(filename, csv, "text/csv");
}

/**
 * Returns true iff e is a text input element. Returns false for null values.
 */
export function isTextElement(e: Element) {
    if (e instanceof HTMLInputElement) {
        switch (e.type.toLowerCase()) {
            // The list of input types supported by HTMLInputElement#setRangeText according to
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement
            case "text":
            case "password":
            case "search":
            case "url":
            case "tel":
                return true;
            default:
                return false;
        }
    }
    return (
        e instanceof HTMLTextAreaElement
        || (e instanceof HTMLElement && !!e.getAttribute("contentEditable"))
    );
}

/**
 * Performs an ordered tree traversal of the given node, calling withTextNode on each Text node in
 * the Node hierarchy. If withTextNode returns a new node (which may be a Dom.fragment), the Text
 * node will be replaced with the returned node. Otherwise, the text node is left as-is.
 */
export function walk(node: Node, withTextNode: (text: Text, parent: Node) => Node | void) {
    if (!node) {
        return;
    }
    let nextNode: Node = node.firstChild;
    while (nextNode) {
        const n = nextNode;
        nextNode = n.nextSibling;
        if (n instanceof Text) {
            const modified = withTextNode(n, node);
            if (modified instanceof Node) {
                node.replaceChild(modified, n);
            }
        } else {
            walk(n, withTextNode);
        }
    }
}
